import { ReactComponent as Caret } from "../caret.svg";

export const CustomSelect = ({ item, bool, value, setter, data }) => {
  return (
    <div className="custom-select-wrapper">
      <div className="custom-select" onClick={() => bool((bool) => !bool)}>
        {item}
        <div
          className={`caret ${value ? "open" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
            bool((bool) => !bool);
          }}
        >
          <Caret />
        </div>
      </div>
      {value && (
        <div className="select-list">
          {data.map((country) => (
            <div
              className="select-item"
              onClick={() => {
                bool((bool) => !bool);
                setter(country.value);
              }}
            >
              {country.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
