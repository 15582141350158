import "./app.css";

import $ from "jquery";
import { Wheel } from "./components/Wheel/Wheel";
import { SignUpModal } from "./components/SignUpModal/SignUpModal";
import { useEffect, useState } from "react";
import { request } from "./utils/http";
import './fonts/marvin.woff'
import './fonts/proximanova_bold.woff'
import './fonts/proximanova_regular.woff'
import './fonts/ProximaNovaRegular.woff'
import './fonts/RobotoCondensed-Regular.woff'
import './'
function App() {
  const [loc, setLocation] = useState("");
  // eslint-disable-next-line no-restricted-globals
  const stag = new URLSearchParams(location.search).get("stag");

  const startWheel = () => {
    if ($(".wheel__button").hasClass("first_spin")) {
      spin_1();
    }
  };

  function spin_1() {
    $(".wheel__button").attr("disabled", "disabled");
    document
      .querySelector(".wheel__spinner")
      .classList.add("wheel__spinner_animated-2");
    setTimeout(function () {
      localStorage.currentSpin = "20020_spin_2";
      $(".fewmodal").css("display", "flex");
      $("#exampleModalToggle-1").css("display", "flex");
    }, 4000);
  }

  useEffect(() => {
    (async () => {
      const response = await request("current_ip", false, 1);
      if (response) {
        setLocation(response);
      }
    })();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("spinned")) {
      window.location.href = "https://www.casinokakadu.com";
    }
  }, []);

  useEffect(() => {
    if (!stag) return;

    (async () => {
      await request(`info/locales?stag=${stag}`, false, 1);
      await request(`info/affiliate`, false, 1);
    })();
  }, []);

  return (
    <>
      <Wheel startWheel={startWheel} location={loc?.country_code} />
      <SignUpModal location={loc} />
    </>
  );
}

export default App;
